import * as React from 'react';
import { Link } from 'react-router-dom';
import http from "../../utils/http";

const AssessorDashboard = () => {
    const [counts, setCounts] = React.useState({});

    const getCounts = React.useCallback(() => {
        http.get(`/api/v1/assessor-dashboard-count`).then((res) => setCounts(res.data));
    }, []);

    React.useEffect(() => {
        getCounts()
    }, [getCounts]);

    return <div>
        <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2">
            <div className="w-full">
                <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 bott-p0 bg-info">
                    <Link to={'/today-assessment'}>
                        <div className="today-a">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white count-t">{counts?.today}</h5>
                            <p className="mb-3 font-normal text-white">Today Assessment</p>
                        </div>
                        <span className="more-info bg-info-dark">More Info <i className="fas fa-arrow-circle-right fa-lg xl:fa-3x"></i></span>
                    </Link>
                </div>
            </div>
            <div className="w-full">
                <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 bott-p0 bg-warning">
                    <Link to={'/pending-assessment'}>
                        <div className="today-a">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white count-t">{counts?.pending}</h5>

                            <p className="mb-3 font-normal text-white">Pending Assessment</p>
                        </div>
                        <span className="more-info bg-warning-dark">More Info <i className="fas fa-arrow-circle-right fa-lg xl:fa-3x"></i></span>
                    </Link>
                </div>
            </div>
            <div className="w-full">
                <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 bott-p0 bg-success">
                    <Link to={'/completed-assessment'}>
                        <div className="today-a">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white count-t">{counts?.completed}</h5>

                            <p className="mb-3 font-normal text-white">Completed Assessment</p>
                        </div>
                        <span className="more-info bg-info-dark">More Info <i className="fas fa-arrow-circle-right fa-lg xl:fa-3x"></i></span>
                    </Link>
                </div>
            </div>
            <div className="w-full">
                <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 bott-p0 bg-danger">
                    <Link to={'/batch-list'}>
                        <div className="today-a">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white count-t">{counts?.batch}</h5>

                            <p className="mb-3 font-normal text-white">Total Batch</p>
                        </div>
                        <span className="more-info bg-info-dark">More Info <i className="fas fa-arrow-circle-right fa-lg xl:fa-3x"></i></span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
};

export default AssessorDashboard;