import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        snackbar: { status: false, message: '', type: 'success', duration: null },
        exams: [],
        batch: []
    },
    reducers: {
        OPEN_SNACKBAR: (state, action) => {
            state.snackbar = { duration: 6000, ...action.payload, status: true }
        },
        CLOSE_SNACKBAR: (state, action) => {
            state.snackbar = { ...action.payload, status: false, message: '', type: 'success', duration: null }
        },
        SET_EXAM: (state, action) => {
            state.exams = action.payload
        },
        SET_BATCH: (state, action) => {
            state.batch = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { OPEN_SNACKBAR, CLOSE_SNACKBAR, SET_EXAM, SET_BATCH } = commonSlice.actions

export default commonSlice.reducer