import * as React from 'react';
import http from "../../utils/http";
import { useNavigate, useParams } from 'react-router-dom';

const CandidateDemo = () => {
    const navigate = useNavigate();
    const { assessment_id, candidate_id } = useParams();
    const [questions, setQuestions] = React.useState([]);
    const [active, setActive] = React.useState(0);
    const [candidate, setCandidate] = React.useState({});
    const [finalSts, setFinalSts] = React.useState(false);
    const [second_lang, setSecondLang] = React.useState({});
    const [count, setCount] = React.useState(6);
    const [instModel, setInsModel] = React.useState(true);
    const [requiredQuestion, setRequiredQuestion] = React.useState(0);

    const getDemoData = React.useCallback(() => {
        http.get(`/api/v1/demo-question/${assessment_id}/${candidate_id}`).then((res) => {
            setQuestions(res.data.questions);
            setRequiredQuestion(res.data?.demoRQC);
            setSecondLang(res.data.languages.find(v => v.slug !== 'eng') || {});
            setCandidate(res.data.user);
        });
    }, [assessment_id, candidate_id]);

    React.useEffect(() => {
        getDemoData()
    }, [getDemoData]);

    const logout = React.useCallback(() => {
        navigate(`/candidate/${assessment_id}`, { replace: true });
        setFinalSts(false);
    }, [assessment_id, navigate])

    React.useEffect(() => {
        if (finalSts) {
            const timeOut = setTimeout(() => {
                logout();
            }, 6000);

            // Clean up the timeOut
            return () => clearTimeout(timeOut);
        }
    }, [finalSts, logout])

    React.useEffect(() => {
        if (finalSts) {
            const timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);

            // Clean up the timer
            return () => clearInterval(timer);
        }
    }, [finalSts, logout])

    if (questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    }

    const getLangQuestion = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.content;
    };

    const getLangDesc = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.description;
    };

    const getLangQuestionData = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q;
    };

    const getQuestionTotalMarks = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        let TotalMarks = q.rubric.reduce((acc, item) => acc + parseInt(item.marks), 0);
        return TotalMarks;
    };

    const getOtherLangQuestionData = (index, lang = 'eng', i) => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.rubric[i]?.content;
    };

    const counterClass = (index) => {
        if (questions.length === 0) return 'bg-black';
        if (index === active) return 'bg-blue';
        else if (questions[index].answer && questions[index].answer !== '0') return 'bg-green';
        else if (questions[index].review) return 'bg-yellow';
        else if (questions[index].visited) return 'bg-red';
        else return 'bg-black';
    };

    const counterClck = (index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        setQuestions(q);
        setActive(index);
    };

    const changeAnswer = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].answer = value;
        setQuestions(q);
    };

    const changeRubricAnswer = (value, index, rubricIndex, content) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].rubric[rubricIndex].answer = value;
        q[index].rubric[rubricIndex].content = content;
        setQuestions(q);
        let TotalMarks = q[index].rubric.filter(item => item.answer !== '').reduce((acc, item) => acc + parseInt(item.answer), 0);
        q[index].answer = TotalMarks;
    };

    const changeRemark = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].remark = value;
        setQuestions(q);
    };

    const saveNext = (index, final = false) => {
        if (questions.length === 0) return false;
        let q = [...questions];
        var post = { question: q[index].question._id, answer: q[index].answer, remark: q[index].remark, rubric: q[index].rubric };
        if (final) {
            post['final_submit'] = true;
            post['user_data'] = { mode: "web" };
        }
        http.post(`/api/v1/demo-answer/${assessment_id}/${candidate_id}`, post).then((res) => {
            if (final) {
                setFinalSts(true);
            } else {
                counterClck(index + 1);
            }
        }).catch(error => {

        })
    };

    return <div className='h-screen w-screen'>
        {instModel ? (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{ zIndex: 101 }} >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-medium">
                                    Assessment Instructions
                                </h3>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto overflow-y-auto" style={{ height: 400 }}>
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                    Read carefully before you begin.
                                </p>

                                <h3>Assessment Instructions</h3>
                                <ul className='being-f mb-5' style={{ listStyle: 'disc', marginLeft: '37px' }}>
                                    <li>All questions are compulsory or stated otherwise</li>
                                    <li>Candidate to answer {requiredQuestion > 0 ? `any ${requiredQuestion}` : "all"} questions</li>
                                    <li>There is no negative marking for incorrect answer.</li>
                                    <li>Candidate Aadhar card is mandatory for assessment</li>
                                    <li>Please take the video for entire 1 minutes. Don't stop the video before that</li>
                                    <li>Press submit button after answering all the questions</li>
                                </ul>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-medium px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => navigate(`/candidate/${assessment_id}`, { replace: true })}
                                >
                                    Decline
                                </button>
                                <button
                                    className="bg-blue-700 text-white active:bg-blue-600 font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setInsModel(false)}
                                >
                                    Start Practical
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ zIndex: 100 }}></div>
            </>
        ) : null}
        {!finalSts && !instModel && <div className='h-full grid grid-cols-8 gap-2'>
            <div className='col-span-6'>
                <div className='w-full h-full grid grid-cols-7 gap-2'>
                    <div className='col-span-7'>
                        <div>
                            <ol type='1' start={active + 1} className="orderRules">
                                <li>
                                    <div className='eng' dangerouslySetInnerHTML={{ __html: getLangQuestion(active) }}></div>
                                    {getLangDesc(active) && <div className='eng inline-block' >Description :- {getLangDesc(active)}</div>}
                                    {Object.keys(second_lang).length > 0 && <>
                                        <div className={second_lang.slug} dangerouslySetInnerHTML={{ __html: getLangQuestion(active, second_lang.slug) }}></div>
                                        {getLangDesc(active, second_lang.slug) && <div className={`${second_lang.slug} inline-block`} >Description :- {getLangDesc(active, second_lang.slug)}</div>}
                                    </>
                                    }
                                </li>
                            </ol>
                        </div>
                        <div className="margin-lt-35">
                            {getLangQuestionData(active)?.rubric?.length > 0 &&
                                <div className="block mb-2 font-medium text-gray-900 dark:text-gray-300">Total Marks: {questions[active].answer}/{getQuestionTotalMarks(active)}</div>
                            }
                            {!(getLangQuestionData(active)?.rubric?.length > 0) && <div className="relative z-0 w-full mb-6 group">
                                <label htmlFor="language" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Marks</label>
                                <select id='language' value={questions[active].answer} onChange={(e) => changeAnswer(e.target.value, active)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full-50">
                                    <option value="">Select Marks</option>
                                    {questions[active]?.max_mark && [...Array(Number(questions[active]?.max_mark))].map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
                                </select>
                            </div>}
                            {getLangQuestionData(active)?.rubric?.length > 0 && getLangQuestionData(active)?.rubric.map((r, i) => <div key={i}>
                                <div>
                                    <ol type='1' start={i + 1} className="orderRules">
                                        <li>
                                            <div className='eng' dangerouslySetInnerHTML={{ __html: r.content }}></div>
                                            {Object.keys(second_lang).length > 0 && <div className={second_lang.slug} dangerouslySetInnerHTML={{ __html: getOtherLangQuestionData(active, second_lang.slug, i) }}></div>}
                                        </li>
                                    </ol>
                                </div>
                                <div className="relative z-0 w-full mb-6 group pl-[30px] mt-[10px]">
                                    <select id='language' value={questions[active]?.rubric[i]?.answer} onChange={(e) => changeRubricAnswer(e.target.value, active, i, r.content)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full-50">
                                        <option value="">Select Marks</option>
                                        {r?.marks && [...Array(Number(r?.marks))].map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
                                    </select>
                                </div>
                            </div>)}
                            <div className="relative z-0 w-full mb-[5.5rem] group margin-t-48">
                                <textarea id="floating_email" onChange={(e) => changeRemark(e.target.value, active)} value={questions[active].remark} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"></textarea>
                                <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Remarks</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 hidden'>
                        <div className="assess-de h-2/5">
                            <p>Candidate</p>
                            <div className="can-he">
                            </div>
                        </div>
                        <div className="assess-de h-2/5">
                            <p>Assessor</p>
                            <div className="can-he">
                                <span className="session"><b>Session ID:-</b> 1653743072</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-2'>
                <div className="assess-de">
                    <p>Practical Detail</p>
                    <ol className="assess-list">
                        <span className="assess-span"><span className="span-float"><b>Candidate Name:</b></span><li>{candidate?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>SSC:</b></span><li>{candidate?.sector?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>QP:</b></span><li>{candidate?.jobrole?.name}</li></span>
                    </ol>
                </div>

                <div className="assess-de">
                    <p>Questions</p>
                    <div className='overflow-y-auto' style={{ maxHeight: 'calc(100vh - 380px)' }}>
                        <div className='grid grid-cols-7 gap-2 py-2 px-2'>
                            {questions.length > 0 && questions.map((v, i) => <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i)}>{i + 1}</button>)}
                        </div>
                    </div>
                    <div className="assess-bott">
                        <div className="rev-com"><span className="common-cls bg-yellow"></span>Review Later</div>
                        <div className="rev-com"><span className="common-cls bg-red"></span>UnAnswered</div>
                        <div className="rev-com not-vis"><span className="common-cls bg-black"></span>Not Visited</div>
                        <div className="rev-com"><span className="common-cls bg-blue"></span>Active</div>
                        <div className="rev-com"><span className="common-cls bg-green"></span>Answered</div>
                    </div>
                </div>
            </div>
            <div className="footerbottom col-span-7">
                <button type="button" disabled={(questions.length - 1) === active} onClick={() => saveNext(active)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rev-lat savne">Save & Next</button>
                {((questions.length - 1) === active) && <button type="button" onClick={() => saveNext(active, true)} className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rev-lat">Submit</button>}
                {/* <p className="all-the">'All the online practical sessions are recorded for future references and audit purposes'</p> */}
            </div>
        </div>}
        {finalSts && !instModel && <div className='h-screen flex items-center justify-center'>
            <div className='w-auto text-center'>
                <div className='text-2xl text-green-600 font-bold'>Practical Submit Successfully.</div>
                <div className='text-lg mt-2'>Now you can click below to button or autoredirect after {count} sec.</div>
                <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To Candidate List</button>
            </div>
        </div>}
    </div>
};

export default CandidateDemo;