import * as React from 'react';
import http from "../utils/http";
import { useDispatch } from 'react-redux';
import { SET_TOKEN, SET_USER } from '../store/auth';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { OPEN_SNACKBAR } from '../store/common';

navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

function Login() {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const setLocationPermission = React.useCallback(() => {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
                console.log('geolocation granted');
            } else {
                navigator.geolocation.getCurrentPosition(function (position) {
                    console.log(position);
                });
            }
        });
    }, []);

    const setMicPermission = React.useCallback(() => {
        navigator.permissions.query({ name: 'microphone' }).then(function (result) {
            if (result.state === 'granted') {
                console.log('microphone granted');
            } else {
                navigator.getUserMedia({ audio: true }, function (stream) {
                    stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }, function (err) {
                    console.log("The following error occurred: " + err.name);
                });
            }
        });
    }, []);

    const setCameraPermission = React.useCallback(() => {
        navigator.permissions.query({ name: 'camera' }).then(function (result) {
            if (result.state === 'granted') {
                console.log('camera granted');
            } else {
                navigator.getUserMedia({ video: true }, function (stream) {
                    stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }, function (err) {
                    console.log("The following error occurred: " + err.name);
                });
            }
        });
    }, []);

    React.useEffect(() => {
        setLocationPermission();
        setCameraPermission();
        setMicPermission();
    }, [setLocationPermission, setCameraPermission, setMicPermission]);

    const handleSubmit = (event, recaptcha_token) => {
        const data = new FormData(event.target);
        http.post('/api/v1/login?web=true', { username: data.get('username'), password: data.get('password'), fcm_token: localStorage.getItem('firebase_token'), recaptcha_token: recaptcha_token }).then((res) => {
            dispatch(SET_USER(res.data.data));
            dispatch(SET_TOKEN(res.data.token));
            setLoading(false)
        }).catch((err) => {
            setLoading(false);
        });
    };

    const handleReCaptchaVerify = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!executeRecaptcha) {
            dispatch(OPEN_SNACKBAR({ message: 'Invalide recaptcha configuration please check admin console!' }));
            setLoading(false);
            return;
        }

        const recaptcha_token = await executeRecaptcha('login');
        // Do whatever you want with the token
        handleSubmit(event, recaptcha_token);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                <div><img src="/logo.png" alt='logo' style={{ height: '64px', margin: '0 auto' }} /></div>
                <h3 className="text-xl font-bold text-center">Login</h3>
                <form onSubmit={handleReCaptchaVerify}>
                    <div className="mt-4">
                        <div>
                            <label className="block" htmlFor="username">Username</label>
                            <input type="text" name='username' placeholder="Username" className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
                        </div>
                        <div className="mt-4">
                            <label className="block">Password</label>
                            <input type="password" name='password' placeholder="Password" className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
                        </div>
                        <div className="flex items-baseline justify-center">
                            <button type='submit' disabled={loading} className="px-2 py-1 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900 flex">
                                {loading ? <><svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                    Processing... </> :
                                    'Login'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default function LoginWithRecaptcha() {
    return <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} useRecaptchaNet="true">
      <Login />
    </GoogleReCaptchaProvider>
}