import * as React from 'react';
import { query, where, addDoc, collection, serverTimestamp, onSnapshot, orderBy} from "firebase/firestore";
import {db} from "../../utils/firestore";
import { useSelector } from 'react-redux';

const AssessorExamChat = ({ id, currentUid, user, onClose }) => {
    const candidate = useSelector(state => state.auth.user);
    const [message, setMessage] = React.useState("");
    const [chats, setChats] = React.useState([]);

    const getChats = React.useCallback(()=>{
        const q = query(
            collection(db, "chat_messages"),
            where('parent', "==", id),
            orderBy("createdAt",'asc')
        );
        onSnapshot(q, async(QuerySnapshot) => {
            let messages = [];
            QuerySnapshot.forEach((doc) => {
                messages.push({ ...doc.data(), id: doc.id });
            });
            setChats(messages);
        });
    },[id]);

    const sendMessage = React.useCallback(async (event) => {
        event.preventDefault();
        if (message.trim() === "") {
          alert("Enter valid message");
          return;
        }
        await addDoc(collection(db, "chat_messages"), {
          parent: id,
          message: message,
          createdAt: serverTimestamp(),
          user: candidate._id,
        });
        setMessage("");
        getChats();
    },[message, id, candidate._id, getChats]);

    React.useEffect(()=>{
        getChats();
    },[getChats]);

    return <React.Fragment>
        <div className='w-96'>
            <div className="flex flex-col flex-grow w-full max-w-xl bg-gray-100 shadow-xl rounded-lg overflow-hidden">
                <div className='flex flex-col flex-grow h-10 p-2 bg-emerald-100'>
                    <div className='flex'>
                    <div className='w-4/5'>{user?.name}</div>
                    <div className='w-1/5 flex justify-end'><img className='w-5 h-5 cursor-pointer' onClick={()=> onClose()} src='/close.png' alt='close' /></div>
                    </div>
                </div>
                <div className="flex flex-col flex-grow h-72 p-4 overflow-auto">
                    {chats.length > 0  && chats.map((chat, ci)=><div key={ci} className={`flex w-full mt-2 space-x-3 max-w-xs ${chat.user !== candidate._id ? 'ml-auto justify-end' : ''}`}>
                        {chat.user === candidate._id && <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>}
                        <div>
                            <div className={`${chat.user === candidate._id ? 'bg-gray-300 p-3 rounded-r-lg rounded-bl-lg' : 'bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg'}`}>
                                <p className="text-sm">{chat.message}</p>
                            </div>
                            <span className="text-xs text-gray-500 leading-none">{chat.createdAt?.seconds }</span>
                        </div>
                        {chat.user !== candidate._id && <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>}
                    </div>)}
                </div>

                <div className="bg-gray-300 p-4">
                    <form onSubmit={sendMessage}>
                        <input className="flex items-center h-10 w-full rounded px-3 text-sm" onChange={(e) => setMessage(e.target.value)} type="text" value={message} placeholder="Type your message…" />
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>;
};

export default AssessorExamChat;