import React, { useState, useEffect } from 'react';
import '../SpeedTyping.css';
import TypingArea from './TypingArea';
import numbers from './numbers.json'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import http from "../../../utils/http";
import { useSelector } from 'react-redux';

const TypingTest = () => {
    // const shuffledNumbers = numbers.sort(() => Math.random() - 0.5);
    const paragraphs = numbers.slice(0, 50);
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);

    const maxTime = 60;

    const [typingText, setTypingText] = useState('');
    const [inpFieldValue, setInpFieldValue] = useState('');
    const [timeLeft, setTimeLeft] = useState(maxTime);
    const [mistakes, setMistakes] = useState(0);
    const [lineMatchIndex, setLineMatchIndex] = useState(0);
    const [isTyping, setIsTyping] = useState(false);
    const [testStarted, setTestStarted] = useState(false);
    const [getResultStatus, setGetResultStatus] = useState(false);
    const [KPH, setKPH] = useState(0);
    const [GKPH, setGKPH] = useState(0);
    const [correction, setCorrection] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [totalKey, setTotalKey] = useState(0);
    const [finalSts, setFinalSts] = React.useState(false);

    const loadParagraph = () => {
        const content = paragraphs.map((number, index) => {
            return (
                <React.Fragment key={index}>
                    <span key={index} className={`char ${index === 0 ? 'active' : ''}`}>
                        {(number !== ' ') ? number : '_'}
                    </span>
                    <br />
                    <br />
                </React.Fragment>
            )
        });
        setTypingText(content);
        setInpFieldValue('');
        setMistakes(0);
        setIsTyping(false);
    };

    const handleKeyDown = (event) => {
        const getWord = document.querySelectorAll('.char');
        const inputArray = inpFieldValue.split('\n')
        if ((event.key === 'Backspace' || event.key === 'Delete') && timeLeft > 0) {
            if (paragraphs.length === inputArray.length || paragraphs.length < inputArray.length) {
                event.preventDefault();
                return;
            }
            setCorrection(correction + 1)

            if (lineMatchIndex > 2 && inputArray[inputArray.length - 1] == '') {
                document.getElementById('paragraph').scrollTop -= 36;
            }

            let original = inputArray.filter(n => n != '')
            if (original && original.length != 0) {
                getWord.forEach(word => word.classList.remove('active'));
                getWord[original.length].classList.add('active');
                setLineMatchIndex(original.length);
            } else if (original && original.length == 0) {
                getWord.forEach(word => word.classList.remove('active'));
                getWord[0].classList.add('active');
                setLineMatchIndex(0);
            } else {
                setLineMatchIndex(0);
            }

        }

        if (event.key === 'Enter' && timeLeft > 0) {
            if (lineMatchIndex + 2 <= paragraphs.length) {
                if (inputArray[inputArray.length - 1] != '') {
                    if (lineMatchIndex > 2) {
                        document.getElementById('paragraph').scrollTop += 36;
                    }
                    getWord.forEach(word => word.classList.remove('active'));
                    if (lineMatchIndex + 1 < getWord.length) {
                        getWord[lineMatchIndex + 1].classList.add('active');
                    }
                    setLineMatchIndex(lineMatchIndex + 1)
                }
            } else {
                setIsTyping(false);
            }
        }
    }

    const initTyping = (event) => {
        let typedChar = event.target.value;
        setInpFieldValue(typedChar)

        const getInputs = inpFieldValue.split('\n').filter(n => n != '')
        if (timeLeft > 0 && getInputs.length == 1) {
            if (!isTyping) {
                setIsTyping(true);
                setTestStarted(true);
            }
        }
        if (lineMatchIndex + 1 == paragraphs.length) {
            let getTypedInputs = typedChar.split('\n');
            if (getInputs.length == paragraphs.length) {
                let getArray = Array.from(String(getTypedInputs[getTypedInputs.length - 1]))
                let getOrgArray = Array.from(String(paragraphs[paragraphs.length - 1]))
                if (getArray.length == getOrgArray.length) {
                    setIsTyping(false);
                }
            }
        }

    };

    useEffect(() => {
        loadParagraph();
    }, []);

    useEffect(() => {
        let interval;
        if (isTyping && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            clearInterval(interval);
            getResult()
            setGetResultStatus(true)
            setIsTyping(false);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isTyping, timeLeft]);


    function compareWords(word1, word2) {
        let matched = 0;
        let mismatched = 0;

        // Ensure both words have the same length
        let minLength = Math.min(word1.toString().length, word2.toString().length);
        for (let i = 0; i < minLength; i++) {
            if (word1.toString()[i] === word2.toString()[i]) {
                matched++;
            } else {
                mismatched++;
            }
        }

        return { matched, mismatched };
    }

    function compareArrays(arr1, arr2) {
        let totalMatched = [];
        let totalMismatched = [];
        let totalCharacters = 0;
        let totalMatchedCharacters = 0;

        // Ensure both arrays have the same length
        let minLength = Math.min(arr1.length, arr2.length);
        for (let i = 0; i < minLength; i++) {
            let { matched, mismatched } = compareWords(arr1[i], arr2[i]);
            totalMatched.push(matched);
            totalMismatched.push(mismatched);
            totalMatchedCharacters += matched;
            totalCharacters += arr1[i].length;
        }

        let accuracy = ((totalMatchedCharacters / totalCharacters) * 100).toFixed(3);

        return { totalMatched, totalMismatched, accuracy };
    }

    const getResult = () => {
        if (testStarted) {
            setIsTyping(false)
            const getInputs = inpFieldValue.split('\n').filter(n => n != '')
            setTotalKey(getInputs.length)

            let { totalMatched, totalMismatched, accuracy } = compareArrays(getInputs, paragraphs);

            let findAllMismatch = totalMismatched.filter(m => Number(m) > 0)
            setMistakes(findAllMismatch.length)
            setAccuracy(accuracy)

            let getSpecialKeyPress = getInputs.length - 1;
            let getTotalKeyStocks = getInputs.reduce((total, str) => total + str.length, 0) + getSpecialKeyPress;

            let totalTimeInHr = (maxTime - timeLeft) / 60;

            let kph = Math.round((getTotalKeyStocks / totalTimeInHr) * 60);
            kph = kph < 0 || !kph || kph === Infinity ? 0 : kph;
            setKPH(kph)

            let gKph = Math.round(((getTotalKeyStocks + correction) / totalTimeInHr) * 60);
            gKph = gKph < 0 || !gKph || gKph === Infinity ? 0 : gKph;
            setGKPH(gKph)

            setGetResultStatus(true)
        } else {
            toast.success('Please Start Test');
        }
    }

    const logout = () => {
        window.location.href = '/';
    };

    const submitResult = () => {
        let data = {
            typingTenKeyResult: {
                mistake: mistakes,
                kph: KPH,
                gKph: GKPH,
                timeTake: maxTime - timeLeft,
                accuracy: accuracy,
                totalKey: totalKey,
                correction: correction
            }
        }
        http.post(`/api/v1/typing-result/${id}`, data).then((res) => {
            setFinalSts(true);
            setTimeout(() => {
                logout();
            }, 6000);
        }).catch(error => {
            console.error(error);
        })
    }

    return (
        <>
            {!finalSts && <div className="container">
                <TypingArea
                    typingText={typingText}
                    inpFieldValue={inpFieldValue}
                    timeLeft={timeLeft}
                    mistakes={mistakes}
                    KPH={KPH}
                    GKPH={GKPH}
                    accuracy={accuracy}
                    correction={correction}
                    totalKey={totalKey}
                    initTyping={initTyping}
                    handleKeyDown={handleKeyDown}
                    getResult={getResult}
                    getResultStatus={getResultStatus}
                    submitResult={submitResult}
                />
            </div>}
            {finalSts && <div className='h-screen flex items-center justify-center'>
                <div className='w-auto text-center'>
                    <div className='text-2xl text-green-600 font-bold'>Ten Key Test Submit Successfully.</div>
                    <div className='text-lg mt-2'>By {user?.name} ({user?.batch?.batch_id})</div>
                    <div className='text-lg mt-2'>Now you can click below to Home or autoredirect after 6 sec.</div>
                    <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To Home</button>
                </div>
            </div>}
        </>

    );
};

export default TypingTest; 
