import React from 'react'
import GoogleMapReact from 'google-map-react'

const LocationPin = ({ text }) => (
    <div className="pin">
        <img src='/map-pin.png' className="w-8 h-8 pin-icon" alt='pin' />
        <p className="pin-text">{text}</p>
    </div>
)

const GoogleMap = ({ location, zoomLevel }) => (
    <div style={{ width: '100%', height: 400 }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCAbKIDZ9BBiyd8TXRYVb6ZHgkzRKvPoLo' }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
        >
            <LocationPin
                lat={location.lat}
                lng={location.lng}
                text={location.address}
            />
        </GoogleMapReact>
    </div>
);

export default GoogleMap